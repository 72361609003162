import * as firebase from 'firebase/app';

export interface AuthProviderMap {
  microsoft: firebase.auth.AuthProvider;
}

export type AuthProvider = keyof AuthProviderMap;

export const instantiateAuthProviders = (
  f: firebase.app.App
): AuthProviderMap => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');

  provider.setCustomParameters({
    tenant: '57952406-af28-43c8-b4de-a4e06f57476d'
  });

  return {
    microsoft: provider
  };
};
