import * as React from 'react';

import './ProviderLogin.scss';
import { AuthProvider } from '../../constants/Auth';
import Icon from '../Icon/Icon';

const microsoft = require('../../components/ProviderLogin/microsoft.png');

export interface ProviderLoginProps {
  onProviderLogin: (provider: AuthProvider) => () => void;
}

interface SelectionCircleProps {
  imageSrc: string;
  imageAlt: string;
  imageTitle: string;
}

const SelectionCircle: React.SFC<SelectionCircleProps> = props => (
  <div className="selection-circle-provider">
    <Icon
      name="circle-selection"
      className="selection-circle-provider__border"
    />
    <img src={props.imageSrc} alt={props.imageAlt} title={props.imageTitle} />
  </div>
);

class ProviderLogin extends React.Component<ProviderLoginProps, {}> {
  render() {
    const { onProviderLogin } = this.props;

    return (
      <div className="login-providers">
        <span className="login-providers__login-with">Login with</span>
        <button
          onClick={onProviderLogin('microsoft')}
          type="button"
          className="login-providers__button"
        >
          <SelectionCircle
            imageSrc={microsoft}
            imageAlt="Microsoft logo"
            imageTitle="Microsoft"
          />
        </button>
      </div>
    );
  }
}

export default ProviderLogin;
